<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <!-- 햄버거 -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <app-navbar-horizontal-layout-brand />

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <li>
        <router-link
          @mouseover="handleMouseOver"
          @mouseleave="handleMouseLeave"
          :to="{ name: 'messages' }"
        >
          <div class="messages-icon">
            <feather-icon
              :badge="msgCount"
              badge-classes="bg-danger"
              icon="MailIcon"
              class="text-body"
              style="margin: 0 0.7rem"
              size="22"
            />
          </div>
        </router-link>
      </li>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="text-white user-name font-weight-bolder mb-0">
              {{ userData.nickname }} 님
            </p>
            <span class="text-white user-status">Lv.{{ userData.rank }}</span>
          </div>
          <b-avatar
            size="40"
            :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_rank/` + userData.rank + `.png`"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'messages' }"
        >
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>쪽지함</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'logout' }"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>로그아웃</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <div
      class="wallet-container"
      :class="{ open: isWallet }"
      @click="toggleWallet"
    >

      <div
        class="wallet"
      >
        <b-img
          :src="require('@/assets/images/icons/coin.svg')"
          class="congratulations-img-left"
        />
      </div>

      <div class="wallet-contents">
        <div class="wallet-users">
          <div>
            <div style="margin-right: 0.5rem">
              <feather-icon icon="ChevronRightIcon" style="margin-right: 0.1rem"></feather-icon>
              <span>Cash</span>
            </div>
            <span class="animated-number" style="color: yellow">{{ animatedCash.toLocaleString() }}</span>
          </div>
          <div>
            <div style="margin-right: 0.5rem">
              <feather-icon icon="ChevronRightIcon" style="margin-right: 0.1rem"></feather-icon>
              <span>Point</span>
            </div>
            <span class="animated-number" style="color: yellow">{{ animatedPoint.toLocaleString() }}</span>
          </div>
        </div>
      </div>
<!--      <div-->
<!--        class="wallet-contents"-->
<!--      >-->
<!--        <div-->
<!--          class="wallet-users"-->
<!--          style=""-->
<!--        >-->
<!--          <div>-->
<!--            <div style="margin-right: 0.5rem">-->
<!--              <feather-icon-->
<!--                icon="ChevronRightIcon"-->
<!--                style="margin-right: 0.1rem"-->
<!--              />-->
<!--              <span>-->
<!--                Cash-->
<!--              </span>-->
<!--            </div>-->
<!--            <span class="animated-number" style="color: yellow">{{ animatedCash.toLocaleString() }}</span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div style="margin-right: 0.5rem">-->
<!--              <feather-icon-->
<!--                icon="ChevronRightIcon"-->
<!--                style="margin-right: 0.1rem"-->
<!--              />-->
<!--              <span>-->
<!--                Point-->
<!--              </span>-->
<!--            </div>-->
<!--            <span class="animated-number" style="color: yellow">{{ animatedPoint.toLocaleString() }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { BAvatar, BDropdownDivider, BDropdownItem, BImg, BLink, BNavbarNav, BNavItemDropdown, } from 'bootstrap-vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import { FETCH_USER_CASH } from '@/store/user/action'
import { FETCH_MESSAGES_USER_CNT } from '@/store/messages/action'

import { createNamespacedHelpers } from 'vuex'
import * as moment from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const notificationStore = createNamespacedHelpers('notificationStore')
const userStore = createNamespacedHelpers('userStore')
const messagesStore = createNamespacedHelpers('messagesStore')

moment().tz('Asia/Seoul')
const fmt = 'YYYY-MM-DD HH:mm:ss'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    AppNavbarHorizontalLayoutBrand,

  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      blinking: false,
      isWallet: true,
      userInfo: {
        userid: '',
        username: '',
        nickname: '',
        recommendNickname: '',
        email: '',
        mobile: '',
        cash: 0,
        point: 0,
        rank: 0,
      },
      periodFrom: moment(new Date()).startOf('month').format(fmt),
      periodTo: moment(new Date()).endOf('month').format(fmt),
      msgCount: '0',
      animatedCash: 0,
      animatedPoint: 0,
    }
  },
  computed: {
    ...notificationStore.mapGetters([
      'notifications',
      'countNotifications',
    ]),
    ...userStore.mapGetters([
      'fetchUserCash',
    ]),
    ...messagesStore.mapGetters({
      fetchMessagesUserCnt: 'fetchMessagesUserCnt',
    }),
  },
  watch: {
    async notifications(newVal) {
      const lastNotification = newVal[newVal.length - 1]
      // console.log('lastNotification ::', lastNotification)

      // Toast Popup
      if (lastNotification.title !== '처리') {
        this.showToast(lastNotification.title, lastNotification.subtitle, 'top-center')
      }

      // Alarm Take
      if (lastNotification.avatar === '신규메세지') {
        if (lastNotification.title === '처리') {
          this.msgCount = String(this.msgCount - 1)
        } else {
          await this.$fetchMessagesUserCnt({
            site: this.$site,
            userid: this.userData.userid,
            role: 'user',
          })
          console.log('cnt ::', this.fetchMessagesUserCnt)
          this.msgCount = String(this.fetchMessagesUserCnt)
        }
      }
      if (lastNotification.avatar === '유저캐쉬변경') {
        await this.$fetchUserCash({
          site: this.$site,
          userid: this.userData.userid,
        })
        this.userInfo = this.fetchUserCash
      }
    },
    userInfo: {
      deep: true,
      handler() {
        this.startBlinking()
      },
    },
    'userInfo.cash'(newVal) {
      this.updateValues(newVal, this.userInfo.point)
    },
    'userInfo.point'(newVal) {
      this.updateValues(this.userInfo.cash, newVal)
    },
  },
  mounted() {
    this.userData = this.$store.getters['authStore/user']
    this.userInfo.userid = this.userData.userid
    this.fetchUserInfo()
    this.fetchMessagesCnt()
    // this.$socket.on('updateUserCash', data => {
    //   try {
    //     if (data) {
    //       console.log('data: ', data)
    //       // this.userInfo = JSON.parse(data)
    //       this.userInfo = data
    //       console.log('updated cash')
    //     } else {
    //       console.error('데이터를 받지 못했습니다')
    //     }
    //   } catch (error) {
    //     console.error('데이터 파싱 오류: ', error)
    //   }
    // })
  },
  methods: {
    ...userStore.mapActions({
      $fetchUserCash: FETCH_USER_CASH,
    }),
    ...messagesStore.mapActions({
      $fetchMessagesUserCnt: FETCH_MESSAGES_USER_CNT,
    }),
    async fetchUserInfo() {
      await this.$fetchUserCash({
        site: this.$site,
        userid: this.userData.userid,
      })
      this.userInfo = this.fetchUserCash
      // console.log('fetchUserCash ::', this.fetchUserCash)
    },
    async fetchMessagesCnt() {
      await this.$fetchMessagesUserCnt({
        site: this.$site,
        userid: this.userData.userid,
        role: 'user',
      })
      console.log('cnt ::', this.fetchMessagesUserCnt)
      this.msgCount = String(this.fetchMessagesUserCnt)
      // this.msgCount = this.fetchMessagesReceiveHistory.filter(item => item.isRead === false).length
    },
    startBlinking() {
      this.blinking = true
      setTimeout(() => {
        this.blinking = false
      }, 5000) // 1초 동안 깜빡이게 하고 효과를 종료합니다.
    },
    toggleWallet() {
      this.isWallet = !this.isWallet
    },
    handleMouseOver() {
      this.isHovered = true
      console.log('Mouse over!')
      // Add additional actions here
    },
    handleMouseLeave() {
      this.isHovered = false
      console.log('Mouse leave!')
      // Add additional actions here
    },
    showToast(title, subtitle, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text: subtitle,
          icon: 'BellIcon',
          variant: 'info',
        },
      },
      {
        position,
        // timeout: 8000,
      })
    },
    animateValue(start, end, duration, updateCallback) {
      let startTime = null

      const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime
        const progress = currentTime - startTime
        const currentValue = Math.floor(start + (end - start) * Math.min(progress / duration, 1))
        updateCallback(currentValue)

        if (progress < duration) {
          requestAnimationFrame(animation)
        }
      }
      requestAnimationFrame(animation)
    },
    updateValues(newCash, newPoint) {
      this.animateValue(this.animatedCash, newCash, 2000, (value) => {
        this.animatedCash = value
      })
      this.animateValue(this.animatedPoint, newPoint, 2000, (value) => {
        this.animatedPoint = value
      })
    },
  },
}
</script>
<style lang="scss">

@keyframes blink {
  0%   {opacity: 0.5;}
  50%  {opacity: 1;}
  100% {opacity: 0.5;}
}

.blinking {
  animation: blink 1s linear infinite;
}

</style>
<style scoped>
.navbar-nav ul.dropdown-menu {
  z-index: 997;
}
.messages-icon .badge.badge-up {
  right: 0px;
  top:-7px;
}

.wallet-container {
  position: fixed;
  padding: 0 2.5rem;
  top: 67px;
  right: 0;
  z-index: 996;
  cursor: pointer;
}
.wallet-container .wallet {
  position: fixed;
  width: 30px;
  height: 30px;
  right: 1.2rem;
  top: calc(60px + 1.2rem);
  transition: transform 0.6s ease-in-out; /* 부드러운 전환 효과 */
}
.wallet-container .wallet img {
  width: 100%;
}
.wallet-container .wallet-contents {
  overflow: hidden;
  white-space: nowrap;
  float: right;
  max-width: 0%;
  /* max-height: 0; */
  transition: max-width 0.3s ease-in-out;
  color: #333;
  background-color: #19223b;
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.24);
}
.wallet-container .wallet-users {
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  color: #ffff;
  font-weight: bolder;
  font-size: 12px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.wallet-container .wallet-users > div {
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wallet-container .wallet-users span {
  overflow: hidden;
}
.wallet-container.open .wallet {
  transform: rotateY(360deg);
}
.wallet-container.open .wallet-contents {
  max-width: 100%;
  width: auto;
  /* max-height: 100px; */
}
.wallet-container.open .wallet-users {
  opacity: 1
}
.messages-icon {
  transition: transform 0.3s;
}
.messages-icon:hover {
  transform: scale(1.2); /* Example hover effect */
}
.animated-number {
  transition: all 0.5s ease-out;
  font-weight: bold;
}
</style>
